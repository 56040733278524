<template>
  <div ref="container" class="bg-surface-default-mid -mx-24 pt-1 lg:px-24">
    <div
      class="relative"
      :class="{ 'border-static-default-low lg:border-b': isSticky }"
    >
      <div
        ref="smScrollContainer"
        class="relative flex items-center justify-between gap-x-8 overflow-auto overscroll-x-contain px-24 scrollbar-none lg:-mx-24 lg:mr-0 lg:overflow-visible lg:px-0"
      >
        <div class="relative lg:min-w-0">
          <div
            ref="lgScrollContainer"
            class="relative flex items-center gap-x-8 py-16 lg:overflow-auto lg:overscroll-x-contain lg:px-24 lg:scrollbar-none"
            :class="{ 'lg:overflow-hidden': expandDisplayed }"
          >
            <div
              v-for="{ facet, value } in displayedFilters"
              :id="facet.name"
              :key="facet.name"
              class="relative"
            >
              <TopFiltersItem
                v-bind="
                  value.length >= 2
                    ? { tag: value.length.toString() }
                    : { value: formatValues(facet.name) }
                "
                :is-active="
                  !!value.length || expandSelectedFacet?.name === facet.name
                "
                :label="facet.title"
                @click.stop="handleFilterFacet($event, facet)"
              />
            </div>
            <TopFiltersItem
              :icon="IconEqualizerSmall"
              :label="i18n(translations.filter)"
              @click.stop="handleFilterAll"
            />
          </div>
          <span
            class="from-bg-surface-default-mid absolute bottom-0 right-0 top-0 hidden w-24 bg-gradient-to-l to-transparent lg:block"
          />
          <span
            class="from-bg-surface-default-mid absolute bottom-0 left-0 top-0 hidden w-24 bg-gradient-to-r to-transparent lg:block"
          />
        </div>
        <TopFiltersItem
          :label="i18n(translations.sort)"
          :value="sortValue?.label"
          @click.stop="handleSort"
        />
      </div>
      <ClientOnly>
        <ExpandFilters
          v-if="expandDisplayed"
          v-model:filters="filters"
          v-model:price="price"
          v-model:sort="sort"
          class="absolute top-full -mt-4"
          :facets
          :price-facet
          :search-nb-results
          :sort-options
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref, toRefs, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { IconEqualizerSmall } from '@ds/icons/IconEqualizerSmall'
import { useIntersectionObserver } from '@vueuse/core'

import type {
  Facet,
  UiPriceFacet,
} from '../../../search/composables/useProductsSearch'
import { useExpandFilters } from '../../composables/useExpandFilters'
import { useFullscreenFilters } from '../../composables/useFullscreenFilters'
import ExpandFilters from '../ExpandFilters.vue'

import translations from './TopFilters.translations'
import TopFiltersItem from './TopFiltersItem.vue'

const { filter: fullscreenFilter, sort: fullscreenSort } =
  useFullscreenFilters()
const {
  filter: expandFilter,
  sort: expandSort,
  close: expandClose,
  displayed: expandDisplayed,
  selectedFacet: expandSelectedFacet,
} = useExpandFilters()
const i18n = useI18n()
const breakpoint = useDangerouslyComputedBreakpoint()
const container = ref()
const smScrollContainer = ref()
const lgScrollContainer = ref()
const isSticky = ref(false)

const props = defineProps<{
  facets: Facet[]
  priceFacet?: UiPriceFacet
  sortOptions: {
    label: string
    value: string
  }[]
  searchNbResults: number
}>()

const { priceFacet, sortOptions } = props
const { facets, searchNbResults } = toRefs(props)

const sort = defineModel<string>('sort', { required: true })
const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})

const allFilters = computed(() =>
  [
    ...(priceFacet
      ? [
          {
            facet: priceFacet,
            value:
              price.value[0] > 0 || price.value[1] < priceFacet.max
                ? [
                    price.value
                      .map(
                        (priceValue) =>
                          i18n.price(priceValue, {
                            maximumFractionDigits: 0,
                          }) || 0,
                      )
                      .join(' - '),
                  ]
                : [],
          },
        ]
      : []),
    ...facets.value.map((facet) => ({
      facet,
      value: filters.value[facet.name],
    })),
  ].filter(
    (o, index, arr) =>
      arr.findIndex((item) => JSON.stringify(item) === JSON.stringify(o)) ===
        index && o.facet.values.length > 0,
  ),
)

const displayedFilters = computed(() =>
  allFilters.value
    .filter((f, i) => i < 5 || f.value?.length)
    .sort((a, b) => {
      if (!!a.value?.length === !!b.value?.length) return 0

      return a.value?.length ? -1 : 1
    }),
)

function formatValues(facetName: string) {
  const filter = filters.value?.[facetName]
  if (Array.isArray(filter)) {
    const filterValueWithoutPrefix = filter.map((value) =>
      value.split(' ').slice(1).join(' '),
    )

    return filterValueWithoutPrefix.join(', ')
  }
  if (typeof filter === 'string') {
    return filter
  }

  return ''
}

const sortValue = computed(() =>
  sortOptions.find((i) => i.value === sort.value),
)

const handleFilterFacet = (
  event: PointerEvent,
  facet: Facet | UiPriceFacet,
) => {
  if (breakpoint.value < Breakpoint.LG) fullscreenFilter(facet)
  else {
    const { offsetLeft } = (event.currentTarget as HTMLInputElement)
      .parentElement as HTMLInputElement

    const scrollLeft = lgScrollContainer.value?.scrollLeft || 0
    expandFilter(facet, {
      left: `${Math.max(offsetLeft - scrollLeft - 24, 0)}px`,
    })
  }
}

const handleFilterAll = () => {
  expandClose()
  fullscreenFilter()
}

const handleSort = () => {
  if (breakpoint.value < Breakpoint.LG) fullscreenSort()
  else expandSort({ right: '0px' })
}

const scrollToLeft = () => {
  const scrollElement =
    breakpoint.value < Breakpoint.LG
      ? smScrollContainer.value
      : lgScrollContainer.value
  scrollElement.scrollTo({
    left: 0,
    behavior: 'smooth',
  })
}

const { stop } = useIntersectionObserver(
  container,
  ([{ boundingClientRect }]) => {
    isSticky.value = boundingClientRect.top < 0
  },
  {
    threshold: [0, 1],
  },
)

watch(
  () => [filters, price],
  () => {
    scrollToLeft()
  },
  { deep: true },
)

onBeforeUnmount(() => {
  stop()
})
</script>
