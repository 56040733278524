export default {
  labelWithoutOffer: {
    id: 'buyback_swap_listing_mobile_label',
    defaultMessage: 'Trade in to get it for less',
  },
  labelWithOffer: {
    id: 'buyback_swap_listing_mobile_label_offer',
    defaultMessage: '{ price } trade-in value applied',
  },
}
