import { useRuntimeConfig, useState } from '#imports'
import { computed, ref, shallowReactive, watch } from 'vue'

import { createInMemoryCache } from '@algolia/cache-in-memory'
import type { AlgoliaProduct } from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import type { SearchConfiguration } from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'
import {
  algoliaHitToProduct,
  algoliaHitToVariant,
} from '@backmarket/nuxt-layer-recommendation/helpers.ts'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import algoliasearch from 'algoliasearch'

import {
  filtersToWhereClause,
  getGraphFacet,
  hasValues,
  transformFacetsValues,
} from '../algolia/algoliaFunctions'

interface Value {
  label: string
  name: string
  position: number
  count: number
}

export interface Facet {
  title: string
  name: string
  type: 'checkbox' | 'radio'
  values: Value[]
}

export interface UiPriceFacet {
  avg: number
  max: number
  name: string
  step: number
  title: string
  values: number[]
}

export interface SearchTrackingData {
  id?: string
  index?: string
  productObjectId?: string
}

const DEBOUNCE_DELAY = 500

export async function useProductsSearch(
  appId: string,
  configuration: SearchConfiguration,
  apiKey: string,
  initialQueryValue = '',
  displayHero = false,
) {
  const logger = useLogger()
  const products = ref<Product[]>([])
  const heroProduct = ref<Product>()
  const total = ref(0)
  const facets = ref<Facet[]>([])
  const sortInput = ref(configuration.indexes.active.name)
  const pageInput = ref(0)
  const pageCount = ref(0)
  const priceInput = ref<[number, number]>([0, 0])
  const priceFacet = ref<UiPriceFacet>()
  const query = ref(initialQueryValue)
  const resettingFilters = ref(false)

  const index = computed(() => {
    return (
      [
        configuration.indexes.active,
        ...(configuration.indexes?.other ?? []),
      ].find(({ name }) => name === sortInput.value) ??
      configuration.indexes.active
    )
  })

  const searchTrackingData = useState<SearchTrackingData>(
    'search-query',
    () => ({
      id: '',
      index: '',
    }),
  )

  const filtersInput = shallowReactive(
    configuration.facets.reduce<Record<string, string[]>>((acc, item) => {
      return {
        ...acc,
        [item.name]: [],
      }
    }, {}),
  )

  const facetCount = computed(() => {
    const priceFiltered =
      priceInput.value[0] > 0 || priceInput.value[1] !== priceFacet.value?.max
    const numberOfFiltersActive = Object.values(filtersInput).filter(
      (filterValue) => filterValue.length > 0,
    ).length

    return numberOfFiltersActive + (priceFiltered ? 1 : 0)
  })

  const algoliaClient = algoliasearch(appId, apiKey, {
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false }),
  })

  function resetPriceFilter() {
    priceInput.value[0] = 0
    priceInput.value[1] = priceFacet.value?.max ?? 0
  }

  function resetFilters() {
    for (const [key] of Object.entries(filtersInput)) {
      filtersInput[key] = []
    }
  }

  function resetAllFilters() {
    resettingFilters.value = true
    resetPriceFilter()
    resetFilters()
    resettingFilters.value = false
  }

  async function search() {
    const defaultFacets = ['price', 'page', 'q', 'sort']
    const sidebarFacets = configuration.facets.map(({ name }) => name)
    const {
      public: { FF_SWAP_PLP_BLOCKS },
    } = useRuntimeConfig()
    const { countryCode } = useMarketplace().market

    const hitsPerPage = (FF_SWAP_PLP_BLOCKS ?? '').includes(countryCode)
      ? 31
      : 32

    const priceFacets =
      configuration.priceFacet?.scales.map((facet) => facet.facetName) ?? []
    const requestOptions = {
      ...index.value.queryParams,
      filters: filtersToWhereClause(filtersInput, configuration.complexFilter),
      facets: [...defaultFacets, ...sidebarFacets, ...priceFacets],
      numericFilters:
        priceInput.value[1] > 0
          ? [`price>=${priceInput.value[0]}`, `price<=${priceInput.value[1]}`]
          : undefined,
      page: pageInput.value,
      hitsPerPage,
    }

    const algoliaIndex = algoliaClient.initIndex(index.value.name)

    try {
      const {
        nbHits,
        facets_stats: algoliaFacetsStats,
        hits,
        queryID,
        nbPages,
        facets: algoliaFacets,
        processingTimeMS,
      } = await algoliaIndex.search<AlgoliaProduct>(query.value, requestOptions)

      const algoliaPriceValues = {
        avg: algoliaFacetsStats?.price.avg ?? 0,
        max: algoliaFacetsStats?.price.max ?? 0,
      }

      searchTrackingData.value = {
        id: queryID,
        index: index.value.name,
      }

      total.value = nbHits
      if (configuration.productGroupingType === 'parent') {
        products.value = hits
          .map((hit) => algoliaHitToProduct(hit))
          .filter(
            (hit) =>
              hit.productPageLink.params.slugV2 &&
              hit.productPageLink.params.uuid,
          )
      } else if (configuration.productGroupingType === 'variant') {
        products.value = hits
          .map((hit) => algoliaHitToVariant(hit))
          .filter(
            (hit) =>
              hit.productPageLink.params.slugV2 &&
              hit.productPageLink.params.uuid,
          )
      }
      pageCount.value = nbPages
      facets.value = configuration.facets.map((conf) => {
        const isFilteredFacet = hasValues(conf.name, filtersInput)
        const values = transformFacetsValues(
          algoliaFacets?.[conf.name],
          conf.isSortedByBusiness,
        )

        return {
          title: conf.title,
          name: conf.name,
          type: conf.type,
          values: isFilteredFacet
            ? (facets.value.find((facet) => facet.name === conf.name)?.values ??
              values)
            : values,
        }
      })

      if (!priceFacet.value) {
        priceFacet.value = getGraphFacet(
          configuration.priceFacet,
          algoliaFacets,
          algoliaPriceValues,
        )

        priceInput.value = [0, priceFacet.value?.max ?? 0]
      }
      logger.info('[S&R] PLP search query success', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_SUCCESS',
        searchProcessingTime: processingTimeMS,
        owners: ['bot-squad-search-and-recommendation-front'],
      })
    } catch (e) {
      logger.error('[S&R] PLP search query failed', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_FAIL',
        e,
        owners: ['bot-squad-search-and-recommendation-front'],
      })
    }
  }

  async function searchHero() {
    if (!displayHero) return
    const requestOptions = {
      ...index.value.queryParams,
      filters: filtersToWhereClause(filtersInput, configuration.complexFilter),
      page: 0,
      hitsPerPage: 1,
    }

    const algoliaIndex = algoliaClient.initIndex(index.value.name)

    try {
      const { hits, processingTimeMS } =
        await algoliaIndex.search<AlgoliaProduct>(query.value, requestOptions)

      heroProduct.value = hits.map((hit) => algoliaHitToProduct(hit))[0]

      logger.info('[S&R] PLP hero search query success', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_SUCCESS',
        searchProcessingTime: processingTimeMS,
        owners: ['bot-squad-search-and-recommendation-front'],
      })
    } catch (e) {
      logger.error('[S&R] PLP hero search query failed', {
        endpointName: 'algoliaSearch',
        type: 'API_REQUEST_FAIL',
        e,
        owners: ['bot-squad-search-and-recommendation-front'],
      })
    }
  }

  await Promise.all([search(), searchHero()])

  watch(
    [() => ({ ...filtersInput }), sortInput, pageInput],
    () => {
      if (!resettingFilters.value) void search()
    },
    { deep: true },
  )

  watch(
    () => query,
    () => {
      resetAllFilters()
      void search()
    },
    { deep: true },
  )

  const debouncedSearch = useDebounceFn(search, DEBOUNCE_DELAY)

  watch(priceInput, debouncedSearch, { deep: true })

  return {
    total,
    pageCount,
    products,
    heroProduct,
    facets,
    priceFacet,
    facetCount,
    filtersInput,
    sortInput,
    pageInput,
    priceInput,
    query,
  }
}
