<template>
  <div>
    <SkinnyBanner page="landing" />
    <RevContainer class="mb-16 mt-32" data-spec="body">
      <div data-spec="main">
        <section data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-32 lg:flex">
            <div class="heading-1">
              {{ i18n(translations.recapTitle) }} <span>{{ query }}</span>
            </div>
          </div>
          <div class="sticky -top-1 z-[1]">
            <TopFilters
              v-model:filters="filtersInput"
              v-model:price="priceInput"
              v-model:sort="sortInput"
              :facets
              :price-facet
              :search-nb-results="total"
              :sort-options
            />
          </div>
          <div class="mt-16 lg:flex lg:gap-16">
            <main class="w-full">
              <span
                class="text-static-default-low body-2 mb-12 block lg:text-caption md:mb-16"
                data-selector="total-products"
                data-test="total-products"
              >
                {{
                  i18n(translations.resultsCount, {
                    total,
                  })
                }}
              </span>
              <SwapPill v-if="displaySwapComponent" class="lg:hidden" />

              <NoResults
                v-if="products.length === 0"
                class="my-32"
                data-spec="body"
              />
              <ResultGrid v-else class="mt-8 md:mt-32" :products />
            </main>
          </div>

          <Pagination v-model="pageInput" :page-count />
        </section>
      </div>
    </RevContainer>
    <FullscreenFilters
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets
      :price-facet
      :search-nb-results="total"
      :sort-options
    />
  </div>
</template>

<script lang="ts" setup>
import { createError, useHead, useRoute, useRuntimeConfig } from '#imports'
import { computed, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'

import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import SwapPill from '~/scopes/buyback/swap/components/SwapPill/SwapPill.vue'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import NoResults from '../components/NoResults.vue'
import Pagination from '../components/Pagination.vue'
import ResultGrid from '../components/ResultGrid.vue'
import TopFilters from '../components/TopFilters/TopFilters.vue'
import { useFiltersFromHash } from '../composables/useHashFilters'

import translations from './SearchResultPage.translations'

const i18n = useI18n()
const { countryCode } = useMarketplace().market

const { data: searchConfig } = await useAlgoliaConfiguration('search-landing')

if (searchConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const { data: apiKeyConfig } = await useAlgoliaApiKey(
  searchConfig.value.indexType,
)

if (!apiKeyConfig.value) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const {
  public: { ALGOLIA_ID: appId, FF_SWAP_PLP_BLOCKS },
} = useRuntimeConfig()

const sortOptions = getSortOptions(searchConfig.value.indexes)

const route = useRoute()
const initialQueryValue =
  (Array.isArray(route.query.q) ? route.query.q[0] : route.query.q) ?? ''

const {
  total,
  products,
  facets,
  pageCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
  query,
} = await useProductsSearch(
  appId,
  searchConfig.value,
  apiKeyConfig.value.apiKey,
  initialQueryValue,
)

useFiltersFromHash(filtersInput, priceInput, pageInput)

watch(
  () => route.query.q,
  (newQuery) => {
    // react to route changes...
    query.value = (Array.isArray(newQuery) ? newQuery[0] : newQuery) ?? ''
  },
)

const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
useHead({
  meta: [
    {
      vmid: 'robots',
      name: 'robots',
      content: 'noindex',
    },
  ],
  title: query,
  titleTemplate: `%s | ${COMPANY_NAME}`,
})

const displaySwapComponent = computed(() =>
  (FF_SWAP_PLP_BLOCKS ?? '').includes(countryCode),
)
</script>
