<template>
  <ul class="rounded-md flex flex-col gap-16">
    <li v-for="sortOption in sortOptions" :key="sortOption.value">
      <RevRadio
        :id="`sort-${sortOption.value}`"
        v-model="sort"
        :value="sortOption.value"
        variant="full"
      >
        <template #label>
          <span>
            {{ sortOption.label }}
          </span>
        </template>
      </RevRadio>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevRadio } from '@ds/components/Radio'

const { sortOptions } = defineProps<{
  sortOptions: {
    label: string
    value: string
  }[]
}>()

const sort = defineModel<string>('sort', { required: true })

const { trackClick } = useTracking()

watch(sort, (newSort) => {
  trackClick({
    name: newSort,
    zone: 'mobileSort',
  })
})
</script>
