<template>
  <div>
    <div v-if="selectedFacet === undefined">
      <RevList :has-external-borders="false">
        <RevListItemInteractive
          v-if="priceFacet"
          @click="selectFacet(priceFacet)"
        >
          <template #label>
            {{ priceFacet.title }}
          </template>

          <template #description>
            <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap">
              {{
                price[0] > 0 || price[1] !== priceFacet.max
                  ? `${i18n.price({ amount: price[0].toString(), currency: defaultCurrency })}, ${i18n.price({ amount: price[1].toString(), currency: defaultCurrency })}`
                  : ''
              }}
            </div>
          </template>

          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemInteractive>
        <RevListItemInteractive
          v-for="facet in facets"
          :key="facet.name"
          @click="selectFacet(facet)"
        >
          <template #label>
            {{ facet.title }}
          </template>

          <template #description>
            <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap">
              {{ formatValues(facet.name) }}
            </div>
          </template>

          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemInteractive>
      </RevList>
    </div>
    <div v-else-if="selectedFacet.name === 'price' && priceFacet">
      <PriceFilter
        v-model="price"
        :avgPrice="priceFacet.avg"
        :currency="defaultCurrency"
        :max="priceFacet.max"
        :prices="priceFacet.values"
        :step="priceFacet.step"
        variant="full"
      />
    </div>
    <div v-else>
      <FacetFilter
        v-if="isStandardFacet(selectedFacet)"
        v-model="filters[selectedFacet.name]"
        :disabled="false"
        :name="selectedFacet.name"
        :title="selectedFacet.title"
        tracking="mobileFilter"
        :type="selectedFacet.type"
        :values="selectedFacet.values"
        variant="full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconChevronRight } from '@ds/icons/IconChevronRight'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import FacetFilter from './Refinements/FacetFilter.vue'
import PriceFilter from './Refinements/PriceFilter.vue'

const { facets, priceFacet } = defineProps<{
  facets: Facet[]
  priceFacet: UiPriceFacet | undefined
}>()

const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})

const price = defineModel<[number, number]>('price', {
  required: true,
})

const { selectFacet, selectedFacet } = useFullscreenFilters()

const i18n = useI18n()
const { defaultCurrency } = useMarketplace().market

function formatValues(facetName: string) {
  const filter = filters.value?.[facetName]
  if (Array.isArray(filter)) {
    const filterValueWithoutPrefix = filter.map((value) =>
      value.split(' ').slice(1).join(' '),
    )

    return filterValueWithoutPrefix.join(', ')
  }
  if (typeof filter === 'string') {
    return filter
  }

  return ''
}

function isStandardFacet(
  facet: Facet | UiPriceFacet | undefined,
): facet is Facet {
  return (
    facet !== undefined && facet.name !== undefined && facet.name !== 'price'
  )
}
</script>
