export default {
  title: {
    id: 'buyback_swap_listing_title',
    defaultMessage: 'Trade in to get it for less',
  },
  caption: {
    id: 'buyback_swap_listing_caption',
    defaultMessage: 'Trade-in value applied',
  },
  blockTextWithoutOffer: {
    id: 'buyback_swap_listing_text',
    defaultMessage:
      'Today’s purchase could set you back a lot less when you trade in an old device at the same time.',
  },
  blockTextWithOffer: {
    id: 'buyback_swap_listing_text_offer',
    defaultMessage:
      'The trade-in value of your { device } is reflected in the prices of the items on this page.',
  },
  buttonCta: {
    id: 'buyback_swap_listing_button_cta',
    defaultMessage: 'Get an offer',
  },
  buttonInfo: {
    id: 'buyback_swap_listing_button_info',
    defaultMessage: 'See how Trade-in works',
  },
  buttonSee: {
    id: 'buyback_swap_listing_button_see',
    defaultMessage: 'See your offer',
  },
}
