<template>
  <div
    v-click-outside="handleClose"
    class="bg-surface-default-low shadow-long rounded-lg hidden max-h-[60vh] w-[320px] max-w-full flex-col py-16 lg:flex"
    :style="position"
  >
    <div class="overflow-auto px-16 pb-16">
      <template v-if="type === 'filter'">
        <PriceFilter
          v-if="selectedFacet?.name === 'price' && priceFacet"
          v-model="livePrice"
          :avgPrice="priceFacet.avg"
          :currency="defaultCurrency"
          display-infos
          :max="priceFacet.max"
          :prices="priceFacet.values"
          :step="priceFacet.step"
          variant="full"
        />
        <FacetFilter
          v-else-if="isStandardFacet(selectedFacet)"
          v-model="liveFilters[selectedFacet.name]"
          :disabled="false"
          display-infos
          :name="selectedFacet.name"
          :title="selectedFacet.title"
          tracking="mobileFilter"
          :type="selectedFacet.type"
          :values="selectedFacet.values"
          variant="full"
        />
      </template>
      <Sort v-else v-model:sort="sort" :search-nb-results :sort-options />
    </div>
    <div class="px-16">
      <RevButton class="w-full" variant="primary" @click="handleSeeAll">
        {{ i18n(translations.validate, { count: searchNbResults }) }}
      </RevButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'
import { RevButton } from '@ds/components/Button'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import { useExpandFilters } from '../composables/useExpandFilters'

import translations from './MobileFilters.translations'
import FacetFilter from './Refinements/FacetFilter.vue'
import PriceFilter from './Refinements/PriceFilter.vue'
import Sort from './Sort.vue'

const { searchNbResults, priceFacet } = defineProps<{
  searchNbResults: number
  sortOptions: {
    label: string
    value: string
  }[]
  facets: Facet[]
  priceFacet?: UiPriceFacet
}>()
const { defaultCurrency } = useMarketplace().market

const i18n = useI18n()
const sort = defineModel<string>('sort', { required: true })
const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})
const liveFilters = ref({ ...filters.value })
const livePrice = ref(price.value)

const { type, selectedFacet, close, position } = useExpandFilters()
const { trackClick } = useTracking()

function applyModels() {
  for (const key of Object.keys(liveFilters.value)) {
    filters.value[key] = liveFilters.value[key]
  }
  price.value = livePrice.value
}

function handleClose() {
  applyModels()
  close()
}

function handleSeeAll() {
  handleClose()
  trackClick({
    zone: type.value === 'filter' ? 'desktopFilter' : 'desktopSort',
    name: 'see_results',
  })
}

function isStandardFacet(
  facet: Facet | UiPriceFacet | undefined,
): facet is Facet {
  return (
    facet !== undefined && facet.name !== undefined && facet.name !== 'price'
  )
}
</script>
