<template>
  <RevDrawer
    class="bg-static-default-low fixed right-0 top-0 z-20 flex h-screen w-full flex-col lg:rounded-l-sm lg:w-380"
    :close-button-label="i18n(translations.close)"
    :name="MODAL_NAMES.FULLSCREEN_FILTERS"
    :title
    @close="clearFacet"
  >
    <template #beforeTitle>
      <RevButtonIcon
        v-if="selectedFacet && !disableGoBack"
        :alternative-text="i18n(translations.close)"
        :icon="IconArrowLeft"
        variant="secondary"
        @click="clearFacet"
      />
      <RevButtonIcon
        v-else
        :alternative-text="i18n(translations.close)"
        :icon="IconCross"
        variant="secondary"
        @click="close"
      />
    </template>
    <template #afterTitle>
      <RevButton v-if="showErase" size="small" variant="primary" @click="erase">
        {{ i18n(translations.erase) }}
      </RevButton>
    </template>
    <template #body>
      <div class="grow overflow-auto">
        <Filters
          v-if="type === 'filter'"
          v-model:filters="filters"
          v-model:price="price"
          :facets
          :price-facet
          :search-nb-results
        />
        <Sort v-else v-model:sort="sort" :search-nb-results :sort-options />
      </div>
    </template>
    <template #footer>
      <RevButton class="w-full" variant="primary" @click="handleSeeAll">
        {{ i18n(translations.validate, { count: searchNbResults }) }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevDrawer } from '@ds/components/Drawer'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconCross } from '@ds/icons/IconCross'

import { MODAL_NAMES } from '~/scopes/product-list/constants'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import { useFullscreenFilters } from '../composables/useFullscreenFilters'

import Filters from './Filters.vue'
import translations from './MobileFilters.translations'
import Sort from './Sort.vue'

const { searchNbResults, priceFacet, facets, sortOptions } = defineProps<{
  searchNbResults: number
  sortOptions: {
    label: string
    value: string
  }[]
  facets: Facet[]
  priceFacet?: UiPriceFacet
}>()

const i18n = useI18n()
const sort = defineModel<string>('sort', { required: true })
const filters = defineModel<Record<string, string[]>>('filters', {
  required: true,
})
const price = defineModel<[number, number]>('price', {
  required: true,
})

const { type, clearFacet, title, selectedFacet, close, disableGoBack } =
  useFullscreenFilters()
const { trackClick } = useTracking()

function handleSeeAll() {
  close()
  trackClick({
    zone: type.value === 'filter' ? 'mobileFilter' : 'mobileSort',
    name: 'see_results',
  })
}

function isStandardFacet(
  facet: Facet | UiPriceFacet | undefined,
): facet is Facet {
  return (
    facet !== undefined && facet.name !== undefined && facet.name !== 'price'
  )
}

function isFacetFiltered(
  facet: Facet | UiPriceFacet | undefined,
): facet is Facet {
  if (isStandardFacet(facet)) {
    const filter = filters.value[facet.name]

    return filter.length > 0
  }

  return false
}

const isPriceFiltered = computed(() => {
  return price.value[0] !== 0 || price.value[1] !== priceFacet?.max
})

const isAnyFacetFiltered = computed(() => {
  return (
    isPriceFiltered.value ||
    Object.values(filters.value).some((val) =>
      Array.isArray(val) ? val.length !== 0 : !val,
    )
  )
})

function resetPriceFacet() {
  price.value[0] = 0
  price.value[1] = priceFacet?.max ?? 0
}

function resetFacet(facet: Facet | UiPriceFacet) {
  if (isStandardFacet(facet)) {
    filters.value[facet.name] = []
  }
}

function resetAllFacets() {
  for (const facet of facets) {
    resetFacet(facet)
  }
  resetPriceFacet()
  trackClick({
    zone: 'mobileFilter',
    name: 'reset_filter',
  })
}

function resetSort() {
  sort.value = sortOptions[0].value
  trackClick({
    zone: 'mobileFilter',
    name: 'reset_sort',
  })
}
const showErase = computed(() => {
  if (type.value === 'sort') return sort.value !== sortOptions[0].value
  if (selectedFacet.value) {
    return (
      (selectedFacet.value.name === 'price' && isPriceFiltered.value) ||
      isFacetFiltered(selectedFacet.value)
    )
  }

  return isAnyFacetFiltered.value
})

function erase() {
  if (type.value === 'sort') resetSort()
  else if (selectedFacet.value) {
    if (selectedFacet.value.name === 'price') resetPriceFacet()
    else resetFacet(selectedFacet.value)
  } else {
    resetAllFacets()
  }
}
</script>
