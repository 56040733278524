<template>
  <RevInputText
    :id
    v-model="input"
    :disabled="!ready"
    :format="(value) => formatValue(value)"
    :has-clear-button="false"
    :label
    type="number"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import type { InputValue } from '@backmarket/design-system/types/InputValue'
import { RevInputText } from '@ds/components/InputText'

const { min, max } = defineProps<{
  label: string
  min?: number
  max?: number
  id: string
}>()

const input = defineModel<number>()

const ready = ref(false)

const formatValue = (value: InputValue) => {
  let formattedValue = Math.ceil(Number(value))
  if (min) formattedValue = Math.max(formattedValue, min)
  if (max) formattedValue = Math.min(formattedValue, max)

  return formattedValue
}

onMounted(() => {
  ready.value = true
})
</script>
