import type { PriceFacet } from '@backmarket/http-api/src/api-specs-search-reco/search/searchConfiguration'

interface FacetValue {
  count: number
  label: string
  name: string
  position: number
}

export const transformFacetsValues = (
  algoliaFacetValues: Record<string, number> | undefined,
  splitKeys = false,
): FacetValue[] =>
  algoliaFacetValues
    ? Object.entries(algoliaFacetValues)
        .map(([key, count]) => {
          const [position, ...words] = splitKeys ? key.split(' ') : ['0', key]

          return {
            position: parseInt(position, 10),
            label: words.join(' '),
            name: key,
            count,
          }
        })
        .filter((val) => val.count > 0)
        .sort((a, b) => a.position - b.position)
    : []

export function filtersToWhereClause(
  userFilters: Record<string, string[]>,
  initFilter = '',
): string {
  const result = Object.entries(userFilters).reduce(
    (acc: string[], [key, filterValue]) => {
      if (Array.isArray(filterValue) && filterValue.length > 0) {
        const sousChaine = filterValue
          .map((value) => value.replaceAll('"', '\\"'))
          // decodeURI(encodeURI()) ensure to get utf-8 encoded characters
          .map((value) => `${key}: "${decodeURI(encodeURI(value))}"`)
          .join(' OR ')
        acc.push(`(${sousChaine})`)
      } else if (typeof filterValue === 'string') {
        acc.push(`(${key}: "${filterValue}")`)
      }

      return acc
    },
    [],
  )

  result.push(initFilter)

  return result.filter((clause) => clause).join(' AND ')
}

export function hasValues(
  filterName: string,
  filterCollection: Record<string, string[]>,
): boolean {
  const values = filterCollection[filterName]

  return Array.isArray(values) && values.length > 0
}

const RANGE_BAR = 20
export function getGraphFacet(
  priceFacets: PriceFacet | undefined,
  algoliaFacets: Record<string, Record<string, number>> | undefined,
  algoliaFacetsValues: {
    avg: number
    max: number
  },
) {
  if (priceFacets && algoliaFacets && Object.keys(algoliaFacets).length) {
    const sortedScales = priceFacets.scales.toSorted(
      (a, b) => a.maxValue - b.maxValue,
    )
    const correctScale =
      sortedScales.find((scale) => scale.maxValue >= algoliaFacetsValues.max) ??
      sortedScales[sortedScales.length - 1]

    return {
      name: priceFacets.name,
      title: priceFacets.title,
      avg: algoliaFacetsValues.avg,
      max: correctScale.maxValue,
      values: Array.from(
        { length: RANGE_BAR },
        (_, i) => algoliaFacets?.[correctScale.facetName][i] ?? 0,
      ),
      step: Math.floor(correctScale.maxValue / RANGE_BAR),
    }
  }

  return undefined
}
