import { useState } from '#imports'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'

export function useExpandFilters() {
  const displayed = useState('plp-expand-filters-display', () => false)
  const type = useState<'filter' | 'sort'>(
    'plp-expand-filters-type',
    () => 'filter',
  )
  const selectedFacet = useState<Facet | UiPriceFacet | undefined>(
    'plp-expand-filters-selected-facet',
    () => undefined,
  )
  type Position = { left?: string; right?: string }
  const position = useState<Position>('plp-expand-filters-position', () => ({}))

  function close() {
    displayed.value = false
    selectedFacet.value = undefined
    position.value = {}
  }

  function filter(facet: Facet | UiPriceFacet, facetPosition: Position) {
    if (displayed.value && selectedFacet.value?.name === facet.name) close()
    else {
      type.value = 'filter'
      selectedFacet.value = facet
      position.value = facetPosition
      displayed.value = true
    }
  }

  function sort(facetPosition: Position) {
    if (displayed.value && type.value === 'sort') close()
    else {
      type.value = 'sort'
      selectedFacet.value = undefined
      position.value = facetPosition
      displayed.value = true
    }
  }

  return {
    displayed,
    type,
    filter,
    sort,
    close,
    selectedFacet,
    position,
  }
}
