<template>
  <div class="mb-20 grid grid-cols-1 gap-16 md:grid-cols-3 lg:grid-cols-4">
    <SwapBlockListing
      v-if="displaySwapComponent"
      class="shadow-short hidden h-full lg:block"
    />
    <ProductCard
      v-for="(product, index) in products"
      :id="product.id"
      :key="product.listingId"
      :brand="product.brandClean"
      :category="product.category"
      :category-id="product.categoryId"
      :color-swatches="product.specifications?.color ?? []"
      :comparison-products
      :description="product.description"
      :discounted-price="product.discountedPrice"
      :image-alt="product.title"
      :image-src="product.image"
      :is-comparison-add-product-disabled
      :is-comparison-enabled
      :is-responsive-comparison-enabled
      :listing-id="product.listingId"
      :model="product.modelClean"
      :price="product.price"
      :price-new="product.priceNew"
      :review-rating="product.reviewRating"
      :scores="product.scores"
      :specifications="
        Object.entries(product.specifications ?? {})
          .map(([key, values]) => ({
            label: key,
            values: values.map(
              ({ name, value }) => name + (value ? ` (${value})` : ''),
            ),
          }))
          .filter(({ label }) => !excludedSpecifications.has(label))
      "
      :tags="index < 3 ? [i18n(translations.recommendationTag)] : []"
      :title="product.title"
      :url="product.productPageLink"
      @link-click="handleProductClick(product, index)"
      @toggle-comparison-product="emit('toggle-comparison-product', $event)"
    />
    <div
      v-if="marketingContent"
      :ref="
        setupBannerImpressionTracker({
          name: 'landing_page',
          creative: marketingContent.alt,
          position: 'landing_page_3',
        })
      "
      class="md:col-span-3 lg:col-span-4"
      :class="{
        'row-start-4 lg:row-start-2': products.length >= 2,
      }"
    >
      <MarketingCard
        :alt="marketingContent.alt"
        :srcLarge="marketingContent.srcLarge"
        :srcSmall="marketingContent.srcSmall"
        :url="marketingContent.url"
        @click="
          trackLandingBannerClick({
            name: 'landing_page',
            creative: marketingContent.alt,
            position: 'landing_page_3',
          })
        "
      />
    </div>
    <div
      v-if="article"
      class="md:col-span-3 lg:col-span-4"
      :class="{
        'row-start-10 md:row-start-8 lg:row-start-5': products.length >= 7,
      }"
    >
      <ArticleCard
        :id="article.id"
        :category="article.category"
        :imageAlt="article.imageAlt"
        :imageSrc="article.imageSrc"
        :link="article.link"
        :text="article.text"
        :title="article.title"
        variant="full"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig, useState } from '#imports'
import { computed, toRef } from 'vue'

import { useDiscountedPrice } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useDiscountedPrice'
import type { ArticleCard as ArticleCardType } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'
import ArticleCard from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.vue'
import ProductCard from '@backmarket/nuxt-layer-recommendation/ProductCard.vue'
import type {
  ComparisonProduct,
  Product,
} from '@backmarket/nuxt-layer-recommendation/models/product'
import { useClickTracking } from '@backmarket/nuxt-layer-recommendation/useProductTracking'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import SwapBlockListing from '~/scopes/buyback/swap/components/SwapBlockListing/SwapBlockListing.vue'
import type { SearchTrackingData } from '~/scopes/search/composables/useProductsSearch'

import { useImpressionTracker } from '../composables/useImpressionTracker'

import MarketingCard from './MarketingCard.vue'
import translations from './ResultGrid.translations'

const { trackProductClick } = useClickTracking()

const { trackLandingBannerImpression, trackLandingBannerClick } = useTracking()
const { setupImpressionTracker: setupBannerImpressionTracker } =
  useImpressionTracker(trackLandingBannerImpression)

const searchTrackingData = useState<SearchTrackingData>('search-query')
const emit = defineEmits(['toggle-comparison-product'])

const i18n = useI18n()

const props = defineProps<{
  products: Product[]
  marketingContent?: {
    alt: string
    srcSmall: string
    srcLarge: string
    url: string
  }
  article?: ArticleCardType
  reviewsCount?: number
  reviewsRate?: number
  comparisonProducts?: ComparisonProduct[]
  isComparisonEnabled?: boolean
  isComparisonAddProductDisabled?: boolean
  isResponsiveComparisonEnabled?: boolean
}>()

const { products } = useDiscountedPrice(toRef(props, 'products'))
const {
  public: { FF_SWAP_PLP_BLOCKS },
} = useRuntimeConfig()
const { countryCode } = useMarketplace().market

const displaySwapComponent = computed(() =>
  (FF_SWAP_PLP_BLOCKS ?? '').includes(countryCode),
)

const excludedSpecifications = new Set(['color', 'storage'])

function handleProductClick(product: Product, index: number) {
  trackProductClick(product, {
    provider: 'algolia',
    list: 'landing page product',
    position: index + 1,
    source: 'search',
    queryID: searchTrackingData.value?.id,
    index: searchTrackingData.value?.index,
  })
  searchTrackingData.value.productObjectId = product.objectId
}
</script>
