<template>
  <div
    class="relative flex flex-col gap-12"
    :class="{
      'after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:right-0 after:h-36 after:bg-gradient-to-t after:from-[#f9f9f9] after:to-transparent':
        variant === 'default' && !arrivedState.bottom,
    }"
  >
    <div
      v-if="variant === 'default' || displayInfos"
      class="flex items-center gap-12"
    >
      <h3 class="body-1-bold inline-block">
        {{ title }}
      </h3>
      <RevTag
        v-if="selectedValues.length > 0"
        :label="`${selectedValues.length}`"
        variant="secondary"
      />
    </div>

    <div v-if="values.length > 9" class="mb-4">
      <RevInputText
        :id="`input-${name}`"
        v-model="searchQuery"
        :disabled="!ready"
        :icon="IconSearch"
        :label="i18n(translations.productFilterSearch, { facet: title })"
        type="search"
      />
    </div>

    <ul
      v-if="type === 'checkbox'"
      ref="list"
      class="rounded-md flex flex-col gap-16"
      :class="{
        'max-h-[17rem] overflow-y-auto overflow-x-hidden':
          variant === 'default',
      }"
    >
      <li v-if="variant === 'default'">
        <RevCheckbox
          :id="`reset-${name}`"
          :disabled="!ready || selectedValues.length === 0"
          :model-value="selectedValues.length === 0"
          @update:model-value="selectedValues = []"
        >
          <template #label>
            <span class="body-1">{{
              i18n(translations.productFilterNone)
            }}</span>
          </template>
        </RevCheckbox>
      </li>

      <li v-for="value in filteredOptions" :key="value.name">
        <RevCheckbox
          :id="`${name}-${value.name}`"
          v-model="selectedValues"
          :disabled="!ready"
          :value="value.name"
          :variant="variant === 'default' ? 'default' : 'full'"
        >
          <template #label>
            <span>
              {{ value.label }}
            </span>
          </template>
        </RevCheckbox>
      </li>
    </ul>

    <ul
      v-if="type === 'radio'"
      class="flex flex-col gap-16"
      :class="{ 'overflow-y-auto overflow-x-hidden': variant === 'default' }"
    >
      <li v-if="variant === 'default'">
        <RevRadio
          :id="`reset-${name}`"
          :disabled="!ready || selectedValues.length === 0"
          :model-value="selectedValues.length === 0"
          :value="'reset'"
          @change="selectedValues = []"
        >
          <template #label>
            <span class="body-1">{{
              i18n(translations.productFilterNone)
            }}</span>
          </template>
        </RevRadio>
      </li>
      <li v-for="value in filteredOptions" :key="value.name">
        <RevRadio
          :id="`${name}-${value.name}`"
          :disabled="!ready"
          :model-value="selectedValues[0]"
          :value="value.name"
          :variant="variant === 'default' ? 'default' : 'full'"
          @update:model-value="(val) => (selectedValues = [val as string])"
        >
          <template #label>
            <span>
              {{ value.label }}
            </span>
          </template>
        </RevRadio>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevInputText } from '@ds/components/InputText'
import { RevRadio } from '@ds/components/Radio'
import { RevTag } from '@ds/components/Tag'
import { IconSearch } from '@ds/icons/IconSearch'
import { useScroll } from '@vueuse/core'

import translations from './FacetFilter.translations'

const list = ref<HTMLElement | null>(null)
const { arrivedState } = useScroll(list)

const props = defineProps<{
  title: string
  name: string
  type: 'radio' | 'checkbox'
  values: Array<{ label: string; name: string }>
  variant: 'full' | 'default'
  tracking: string
  displayInfos?: boolean
}>()

const i18n = useI18n()
const { trackClick } = useTracking()

const searchQuery = ref('')
const ready = ref(false)

const selectedValues = defineModel<string[]>({
  required: true,
})

onMounted(() => {
  ready.value = true
})

const filteredOptions = computed(() =>
  props.values
    .filter((value) =>
      value.name
        .toLocaleLowerCase()
        .includes(searchQuery.value.toLocaleLowerCase().trim()),
    )
    .filter((item) => item.label),
)

watch(selectedValues, (newInput, oldInput) => {
  if (newInput.length === 0) {
    trackClick({
      name: props.name,
      zone: props.tracking,
      value: 'All',
    })
  } else {
    const value = newInput
      .filter((x) => !oldInput.includes(x))
      .concat(oldInput.filter((x) => !newInput.includes(x)))[0]

    trackClick({
      name: props.name,
      zone: props.tracking,
      value,
    })
  }
})
</script>
