import { useState } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { closeModal, openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/product-list/constants'

import type {
  Facet,
  UiPriceFacet,
} from '../../search/composables/useProductsSearch'
import translations from '../components/MobileFilters.translations'

export function useFullscreenFilters() {
  const i18n = useI18n()
  const displayed = useState('plp-fullscreen-filters-display', () => false)
  const disableGoBack = useState(
    'plp-fullscreen-filters-enable-go-back',
    () => false,
  )
  const type = useState<'filter' | 'sort'>(
    'plp-fullscreen-filters-type',
    () => 'filter',
  )
  const selectedFacet = useState<Facet | UiPriceFacet | undefined>(
    'plp-fullscreen-filters-selected-facet',
    () => undefined,
  )

  const title = computed(() => {
    if (type.value === 'sort') return i18n(translations.titleSort)
    if (selectedFacet.value) return selectedFacet.value.title

    return i18n(translations.titleFilter)
  })

  function filter(facet?: Facet | UiPriceFacet | undefined) {
    type.value = 'filter'
    if (facet) {
      selectedFacet.value = facet
      disableGoBack.value = true
    }
    openModal(MODAL_NAMES.FULLSCREEN_FILTERS)
  }
  function sort() {
    type.value = 'sort'
    openModal(MODAL_NAMES.FULLSCREEN_FILTERS)
  }
  function close() {
    disableGoBack.value = false
    closeModal(MODAL_NAMES.FULLSCREEN_FILTERS)
  }
  function selectFacet(facet: Facet | UiPriceFacet) {
    selectedFacet.value = facet
  }
  function clearFacet() {
    selectedFacet.value = undefined
  }

  return {
    displayed,
    type,
    filter,
    sort,
    close,
    selectedFacet,
    selectFacet,
    clearFacet,
    title,
    disableGoBack,
  }
}
